import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ChildArticles from "../components/child-articles";
import ArticleTopicsList from "../components/article-topics-list";

import topicStyles from "./topic.module.css";

class TopicTemplate extends React.Component {
  render() {
    const { data } = this.props;
    let topicPage = data.topicPage.topic;
    let childArticles = data.childArticles.articles;

    const title = topicPage.category.replace(/_/, " ");

    return (
      <Layout>
        <SEO title={`${title} | Help Center`}/>
        <div className={topicStyles.articlePageWrapper}>
          <div className={topicStyles.content}>
            <h1>{title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: topicPage.richText.html }}
            />
            <div className={topicStyles.childArticles}>
              <ChildArticles articles={childArticles}/>
            </div>
          </div>
          <div className={topicStyles.articleTopicsContainer}>
            <ArticleTopicsList/>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($topicCategory: CMS_Category!) {
    topicPage: cms {
      topic(where: { category: $topicCategory }) {
        category
        richText {
          html
        }
      }
    }
    childArticles: cms {
      articles(where: { category: $topicCategory }) {
        title
        order
        slug
        category
      }
    }
  }
`;

export default TopicTemplate;
