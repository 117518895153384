import React from "react";
import ArticleListing from "./article-listing";

class ChildArticles extends React.Component {
  render() {
    const { articles } = this.props;

    return (
      <div>
        {articles.map(article => {
          const title = article.title || article.slug;
          const category = article.category;
          const rawSlug = article.slug;

          let slug =
            "/" +
            category.replace(/_/g, "-").replace(/([A-Z])/g, v => {
              return v.toLowerCase();
            }) +
            `/${rawSlug}/`;

          return <ArticleListing title={title} slug={slug} key={slug}/>;
        })}
      </div>
    );
  }
}

export default ChildArticles;
